import React, { useEffect, useState } from "react";
import shadow1 from "../../assets/images/Ellipse 195.png";
import shadow2 from "../../assets/images/Ellipse 196.png";
import grass from "../../assets/images/law.png";
import fram33 from "../../assets/images/AbstractDesign3.png";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { useParams } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import { stripHtml } from "../../utils/htmlParser";
import { LoadingWord, ErrorWord } from "../../utils/Loading";
import { useNavigate } from "react-router-dom";
import Loader from "../loader";

function removeHtmlTags(htmlString) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  return tempDiv.textContent || tempDiv.innerText || "";
}



function ServiceDetails() {
  const [servicesDataDetailsApi, setServicesDataDetailsApi] = useState();
  const [loader , setLoader] = useState(true);
  const lang = localStorage.getItem("i18nextLng");
  const textContent = removeHtmlTags(servicesDataDetailsApi?.description);
  const api = async () => {
    setLoader(true)
    let res = await fetch(
      "https://backend-elfahd.web-allsafeeg.com/api/services/details/" + id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          lang: lang || "en",
        },
      }
    );
    let { data } = await res.json();
    setServicesDataDetailsApi(data);
    setLoader(false)
    return data;
  }
  useEffect(() => {
    api();
  }, [lang]);
console.log(servicesDataDetailsApi);
  const { t } = useTranslation("global");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let magnets = document.querySelectorAll(".magnet");
    magnets.forEach((magnet) => {
      const xTo = gsap.quickTo(magnet, "x", {
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      });
      const yTo = gsap.quickTo(magnet, "y", {
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      });
      magnet.addEventListener("mousemove", (e) => {
        const { clientX, clientY } = e;
        const { width, height, left, top } = magnet.getBoundingClientRect();
        const x = clientX - (left + width / 2);
        const y = clientY - (top + height / 2);
        xTo(x);
        yTo(y);
      });
      magnet.addEventListener("mouseleave", (e) => {
        xTo(0);
        yTo(0);
      });
    });
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);

  const { data: serviceData, loading, error } = useFetchData(`/service/${id}`);

  return loader ? <Loader/> :   <>
  <section className="py-5" dir="ltr">
    <div className="container">
      <div className="service-header rounded-4 py-4 mt-5">
        <h2 className="text-center main-font text-white">
          {/* الخدمات القانونية  لمجال العقارات */}
          {/* {serviceData?.name} */}
            { servicesDataDetailsApi?.name}
        </h2>
      </div>
    </div>
  </section>
  <section className="py-5 position-relative">
    <img
      src={shadow1}
      alt="vector"
      className="eclipse position-absolute bottom-0 end-0"
    />
    <img
      src={shadow2}
      alt="vector"
      className="eclipse position-absolute top-50 start-0 d-md-block d-none"
    />
    <div className="container serv-details">
      {/* main desc */}
      <Fade direction="right" className="fade" duration={2000}>
        <div className="row align-items-center py-4 serv-details-wrab g-4">
          <div className="col-md-6">
            <div className="serv-details-text main-font text-white">
              <p className="my-3 secondary-text" dir={lang === "ar" ? "rtl" : "ltr"}>
                {textContent}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={servicesDataDetailsApi.image}
              alt="grass"
              className="w-100 rounded-4"
            />
          </div>
        </div>
      </Fade>
    </div>
  </section>
  <section className="py-5">
    <div className="container">
      <Fade direction="left" duration={1500}>
        <div className="contact-us-footer d-flex align-items-center justify-content-between overflow-hidden z-1 position-relative">
          <img
            src={fram33}
            alt="frame"
            className="position-absolute start-0 top-0 eclipse"
          />
          <div className="contact-us-footer-text text-white main-font fw-bold">
            <h2>
              {t("contact-us-footer.h")}{" "}
              <span className="main-text">
                {t("contact-us-footer.span")}
              </span>
            </h2>
            <p className="secondary-text my-3">
              {t("contact-us-footer.p")}
            </p>
          </div>
          <Link to={"/contact-us"} className="text-decoration-none">
            <button className="my-4 magnet contact-btn-footer px-3 py-2 rounded-3 main-bg">
              <p className="mb-0 text-white main-font">{t("header.btn")}</p>
            </button>
          </Link>
        </div>
      </Fade>
    </div>
  </section>
</>

}

export default ServiceDetails;
