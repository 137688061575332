import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import frame1 from "../../assets/images/Abstract Design.png";
import fram33 from "../../assets/images/AbstractDesign3.png";
import shadow1 from "../../assets/images/Ellipse 195.png";
import shadow2 from "../../assets/images/Ellipse 196.png";
import arrows from "../../assets/images/Group.png";
import icon from "../../assets/images/Icon Container.png";
import img from "../../assets/images/Image.png";
import vecRight from "../../assets/images/Vector.png";
import vecLeft from "../../assets/images/Vector1.png";
import { DirectionContext } from "../Context/DirectionContext";
import "./home.scss";

// star icon
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
// api
import useFetchData from "../../hooks/useFetchData";
import { stripHtml } from "../../utils/htmlParser";

// context 
import { Context } from "../Context/Global";
import Loader from "../loader";

// component to takke number of stars aand return the shape of stars with icons
// HALF star or FULL star
const Stars = ({ number }) => {
  let stars = [];
  const fullStars = Math.floor(number);
  const hasHalfStar = number % 1 !== 0;

  for (let i = 0; i < 5; i++) {
    if (i < fullStars) {
      stars.push(<StarIcon key={i} />);
    } else if (i === fullStars && hasHalfStar) {
      stars.push(
        <StarHalfIcon
          key={i}
          // reverse
          style={{ transform: "scaleX(-1)" }}
        />
      );
    } else {
      stars.push(<StarOutlineIcon key={i} />);
    }
  }
  return <div>{stars}</div>;
};

/// functiolnt to take index and return right or left or up or down
const direction = (index) => {
  // if (idex === 0) return "right";
  // if (idex === 1) return "left";
  // return index % 2 === 0 ? "right" : "left";
  switch (index) {
    case 0:
      return "right";
    case 1:
      return "null";
    case 2:
      return "left";
    case 3:
      return "right";
    case 4:
      return "null";
    case 5:
      return "left";
    case 6:
      return "bottom";
    case 7:
      return "bottom";
    default:
      return "right";
  }
};

gsap.registerPlugin(ScrollTrigger);
function Home() {
  const { dir } = useContext(DirectionContext);
  const container = useRef(null);
  const image1 = useRef(null);
  const image2 = useRef(null);
  const text = useRef(null);
  const websiteData = useContext(Context);
  const [homeData, setHomeData] = useState({});
  const [loader, setLoader] = useState(true);
  const lang = localStorage.getItem("i18nextLng");
  const api = async () => {
    setLoader(true)
    let res = await fetch('https://backend-elfahd.web-allsafeeg.com/api/home', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'lang': lang || 'en'
      }
    })
    let data = await res.json()
    setLoader(false)
    setHomeData(data.data)
    return data
  }

  const render = () => {
    let item;
    let arr = []
    homeData?.services?.map((single, index) => {
      item = (
        <div className="col-xxl-4 col-md-6" key={index}>
          <Fade duration={2000}>
            <Link
              to={`/services/details/${single.id}`}
              className="text-decoration-none"
            >
              <div
                data-cursor-text={t("explore")}
                className="services-card overflow-hidden rounded-4 position-relative"
              >
                <img
                  src={single?.image}
                  className="w-100"
                  height={350}
                  alt="person"
                />
                <div className="services-layer p-3 position-absolute top-0 end-0 start-0 bottom-0">
                  <div className="text-center py-2 px-3">
                    <img src={icon} alt="icon" />
                    <h5 className="py-2 main-font text-white">
                      {single?.name}
                    </h5>
                    <p className="secondary-text py-2 mb-0 main-font">
                      {stripHtml(single?.overview.split('>')[1].split('<')[0])}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </Fade>
        </div>
      )
      arr.push(item)
    })
    return arr
  }
  const renderRoadMap = () => {
    let item;
    let arr = []
    homeData?.roadmap?.map((single, index) => {
      item = (
        <Fade
          direction={index % 2 === 0 ? "right" : "left"}
          key={index}
          duration={2000}
        >
          <div className="row align-items-center py-4 vision-wrab g-4">
            <div
              className={`col-md-7 ${index % 2 !== 0 ? "order-md-2" : ""
                }`}
            >
              <div className={`vision-text main-font text-white ${dir === "rtl" ? "text-end" : "text-start"}`}>
                <h3>
                  {single.title}
                </h3>
                <p className="my-4 secondary-text">
                  {single?.description?.split('>')[1].split('<')[0]}
                </p>
              </div>
            </div>
            <div
              className={`col-md-5 ${index % 2 !== 0 ? "order-md-1" : ""
                }`}
            >
              <div className="vision-image rounded-4 ps-5 pe-5 pt-5">
                <img
                  src={single.image}
                  height={350}
                  alt="grass"
                  className="w-100 rounded-top-4"
                />
              </div>
            </div>
          </div>
        </Fade>
      )
      arr.push(item)
    })
    return arr
  }
  useEffect(() => {
    api()
  }, [lang])
  //   reviews data
  const { data: reviewsData, loading, error } = useFetchData("/clients");

  // services data
  // const { data: servicesData } = useFetchData("/services");

  // about us data
  const { data: aboutUsData } = useFetchData("/about-us");

  // about us values
  const { data: aboutUsValues } = useFetchData("/about-values");


  const { t } = useTranslation("global");

  return loader ? <Loader /> : <>
  <header className="header">
    <div className="container">
      <div ref={text} className="row  min-vh-100 align-items-center">
        <div className="col-lg-8">
          <div className="header-text">
            <Fade direction="up" duration={2000}>
              <h1 className={`${dir === "rtl" ? "text-end" : "text-start"} text-white main-font fw-bold`}>
                {homeData?.heroSection?.title}
              </h1>
              <p className={`${dir === "rtl" ? "text-end" : "text-start"} mb-0 secondary_text secondary-font`}>
                {homeData?.heroSection?.description.split('>')[1].split('<')[0]}
              </p>
            </Fade>
            <Fade direction="up" duration={2000} delay={500}>
              <Link to={"/contact-us"} className="text-decoration-none">
                <button className="my-4 main-btn main-bg magnet">
                  <p className="mb-0 text-white main-font">
                    {t("header.btn")}
                  </p>
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        <div
          className="col-lg-4 d-lg-block
            order-first mt-4 pt-4 order-lg-last
         "
        >
          <div
            className=" mx-auto
            flex justify-content-center align-items-center
          "
            style={{ direction: "rtl" }}
          >
            <img
              src={arrows}
              className="
                
              w-lg-100 bottomRight-to-topLeft"
              alt="arrows"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
  <section ref={container} className="py-5 position-relative">
    <img
      src={shadow1}
      alt="vector"
      className="eclipse position-absolute top-0 end-0"
    />
    <div className="container">
      <div className="row gx-lg-0 g-4 rounded-3 flex-lg-nowrap p-4 h-section position-relative">
        <img
          src={frame1}
          alt="fahad"
          className="position-absolute top-0 end-0 my-0 frame-fix"
        />
        <div ref={image1} className="col-lg-7 transs">
          <Fade direction="up" duration={2000}>
            <img src={img} className="w-100" alt="Fahad" />
          </Fade>
        </div>
        <div ref={image2} className="col-lg-5 transs">
          <Fade direction="left" duration={2000}>
            <div className="card-h p-lg-5 p-md-4 p-2">
              <div className="line mb-5 mt-4"></div>
              <h2 dir={dir} className="text-white main-font h1">
                {homeData?.aboutSection?.title} <br className="d-xlg-block d-none" />{" "}
                <span>{t("1section.span")}</span>
              </h2>
              <p dir={dir} className="lead secondary-text my-4">
                {homeData?.aboutSection?.description.split('>')[1].split('<')[0]}
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  </section>

  {/* reviews */}
  <section className="py-5 position-relative">
    <img
      src={shadow1}
      alt="vector"
      className="eclipse position-absolute top-50 end-0"
    />
    <img
      src={shadow2}
      alt="vector"
      className="eclipse position-absolute top-0 start-0 d-md-block d-none"
    />
    <div className="container">
      <Fade direction="up" duration={1500}>
        <div className="main-section-text py-5 text-white text-center main-font">
          <h2>
            {homeData?.titleReview?.title} <br />{" "}
            <span className="fw-bold main-text">{t("rates.span")}</span>
          </h2>
          <div className="py-5 position-relative">
            <img
              src={vecLeft}
              alt=","
              className="position-absolute start-0 top-0"
            />
            <img
              src={vecRight}
              alt=","
              className="position-absolute end-0 bottom-0"
            />
            <p className="mb-0 secondary-text">{homeData?.titleReview?.description.split('>')[1].split('<')[0]}</p>
          </div>
        </div>
      </Fade>

      <div
        className="row justify-content-center rate-content g-xxl-5 g-4"
        style={{ overflow: "hidden" }}
      >
        {homeData?.reviews?.map((single, index) => (
          <>
            <div className="col-xxl-4 col-lg-6 magnet" key={index}>
              <Fade
                duration={2000}
                direction={direction(index)}
                data-cursor-text={t("explore")}
                key={index}
              >
                <div className="row g-xxl-5 g-1 rate-card align-items-center">
                  <div className="col-3">
                    <div className="rate-img overflow-hidden rounded-circle">
                      <img
                        src={single.image}
                        alt="person"
                        className="w-100 position-relative"
                      />
                    </div>
                  </div>
                  <div className="col-9 rate-text p-3 px-4">
                    <h3 className="main-font text-white">
                      {single.title}
                    </h3>
                    <div
                      style={{ color: "#A34DEC" }}
                      className="d-flex align-items-center"
                    >
                      <Stars number={single.rate} />
                    </div>

                    <p className="py-4 secondary-text main-font px-3 fs-6">
                      {single.comment.split('>')[1].split('<')[0]}
                    </p>
                    <h5 className="text-white main-font">
                      {single.name}
                    </h5>
                    <h6 className="secondary-text main-font">
                      {single.title_job}
                    </h6>
                  </div>
                </div>
              </Fade>
            </div>
          </>
        ))}
      </div>
    </div>
  </section>
  <section className="py-5 position-relative">
    <img
      src={shadow2}
      alt="vector"
      className="eclipse position-absolute top-0 start-0 d-md-block d-none"
    />
    <div className="container">
      <Fade direction="up" duration={1500}>
        <div className="main-section-text py-5 text-white text-center main-font">
          <h2>
            {homeData?.titleServices?.title}
          </h2>
          <div className="py-5 position-relative">
            <p className="mb-0 secondary-text">{homeData?.titleReview?.description.split('>')[1].split('<')[0]}</p>
          </div>
        </div>
      </Fade>
      <div className="row g-4">
        {render()}
      </div>
      <p className="text-white main-font text-start pt-5">
        {t("see_more.p")}{" "}
        <br />
        <Link className="text-white text-decoration-none btn btn-success" to={"/services"}>
          {t("see_more.a")}
        </Link>
      </p>
    </div>
  </section>

  {/* about us section */}
  <section className="py-5 position-relative">
    <img
      src={shadow1}
      alt="vector"
      className="eclipse position-absolute bottom-0 end-0"
    />
    <div className="container">
      <Fade direction="up" duration={1500}>
        <div className="main-section-text py-5 text-white text-center main-font">
          <h2>
            {homeData?.view?.title} <br />{" "}
            <span className="fw-bold main-text">
              {homeData?.aboutSection?.description.split('>')[1].split('<')[0]}
            </span>
          </h2>
        </div>
      </Fade>
      {aboutUsData && (
        <Fade duration={2500}>
          <div className="row h-section p-4 rounded-4 position-relative">
            <img
              src={frame1}
              alt="fahad"
              className="position-absolute top-0 end-0 my-0 frame-fix"
            />
            {/* <div className="col-12" data-cursor-video={aboutUsData?.video}> */}
            <div className="col-12">
              <Fade direction="left" duration={2000}>
                <video
                  src={homeData?.view?.video}
                  muted
                  controls
                  className="w-100 rounded-4"
                ></video>
              </Fade>
            </div>
          </div>
        </Fade>
      )}
    </div>
  </section>

  {/* vision */}
  <section className="p-5 position-relative" style={{ overflow: "hidden" }}>
    <img
      src={shadow1}
      alt="vector"
      className="eclipse position-absolute bottom-0 end-0"
    />
    <img
      src={shadow2}
      alt="vector"
      className="eclipse position-absolute top-50 start-0 d-md-block d-none"
    />
    <div className="container">
      <Fade direction="up" duration={1500}>
        <div className="main-section-text py-5 text-white text-center main-font">
          <h2>
            {t("our_vision.h")} <br />{" "}
            <span className="fw-bold main-text">
              {t("our_vision.span")}
            </span>
          </h2>
        </div>
      </Fade>

      {renderRoadMap()}
    </div>
  </section>
  <section className="py-5">
    <div className="container">
      <Fade direction="left" duration={1500}>
        <div className="contact-us-footer d-flex align-items-center justify-content-between overflow-hidden z-1 position-relative">
          <img
            src={fram33}
            alt="frame"
            className="position-absolute start-0 top-0 eclipse"
          />
          <div className="contact-us-footer-text text-white main-font fw-bold">
            <h2>
              {t("contact-us-footer.h")}{" "}
              <span className="main-text">
                {t("contact-us-footer.span")}
              </span>
            </h2>
            <p className="secondary-text my-3">
              {t("contact-us-footer.p")}
            </p>
          </div>
          <Link to={"/contact-us"} className="text-decoration-none">
            <button

              className="my-4  contact-btn-footer px-3 py-2 rounded-3 main-bg magnet">
              <p className="mb-0 text-white main-font">{t("header.btn")}</p>
            </button>
          </Link>
        </div>
      </Fade>
    </div>
  </section>
</>
}

export default Home;
