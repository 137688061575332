import React, { useContext, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import frame1 from "../../assets/images/Abstract Design.png";
import fram33 from "../../assets/images/AbstractDesign3.png";
import shadow1 from "../../assets/images/Ellipse 195.png";
import shadow2 from "../../assets/images/Ellipse 196.png";
import icon from "../../assets/images/Icon Container.png";
import { DirectionContext } from "../Context/DirectionContext";
import Loader from "../loader";


function Services() {
  const { dir } = useContext(DirectionContext);
  const { t } = useTranslation("global");
  const [servicesDataApi, setServicesDataApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const lang = localStorage.getItem("i18nextLng") || "en";

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await fetch("https://backend-elfahd.web-allsafeeg.com/api/services", {
        method: "GET",
        headers: {
          Accept: "application/json",
          lang: lang,
        },
      });
      if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
      const { data } = await res.json();
      setServicesDataApi(data);
    } catch (error) {
      console.error('Fetch error: ', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  if (loading) return <Loader />; // Show the loader while loading
  if (error) return <p>Error: {error}</p>;

  const renderServices = () => {
    if (!servicesDataApi || !servicesDataApi.services) return null;

    return servicesDataApi.services.map((service) => (
      <div className="col-xxl-4 col-md-6" key={service.id}>
        <Fade duration={2000}>
          <Link to={`/services/details/${service.id}`} className="text-decoration-none">
            <div
              data-cursor-text={t("explore")}
              className="services-card overflow-hidden rounded-4 position-relative"
            >
              <img src={service.image} height={350} className="w-100" alt={service.name} />
              <div className="services-layer p-3 position-absolute top-0 end-0 start-0 bottom-0">
                <div className="text-center py-2 px-3">
                  <img src={icon} alt="icon" />
                  <h5 className="py-2 main-font text-white">{service.name}</h5>
                  <p className="secondary-text py-2 mb-0 main-font">
                    {service.name}
                    <br />
                    {service.overview.split(">")[1].split("<")[0]}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </Fade>
      </div>
    ));
  };

  return (
    <>
      <section className="py-5 position-relative">
        <img src={shadow1} alt="vector" className="eclipse position-absolute top-0 end-0" />
        <div className="container">
          <div className="row gx-lg-0 g-4 rounded-3 flex-lg-nowrap p-4 h-section position-relative">
            <img
              src={frame1}
              alt="fahad"
              className="position-absolute top-0 end-0 my-0 frame-fix"
            />
            <div className="col-lg-7 transs">
              <Fade direction="up" duration={2000}>
                <img
                  src={servicesDataApi?.title.background}
                  height={500}
                  className="w-100 rounded-4"
                  alt="Background"
                />
              </Fade>
            </div>
            <div className="col-lg-5 transs">
              <Fade direction="left" duration={2000}>
                <div className="card-h p-lg-5 p-md-4 p-2">
                  <div className="line mb-5 mt-4"></div>
                  <h2 dir={dir} className="text-white main-font h1">
                    {servicesDataApi?.title.title}
                    <br className="d-xlg-block d-none" />
                  </h2>
                  <p dir={dir} className="lead secondary-text my-4">
                    {servicesDataApi?.title.description.split(">")[1].split("<")[0]}
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 position-relative">
        <img src={shadow2} alt="vector" className="eclipse position-absolute top-0 start-0 d-md-block d-none" />
        <div className="container">
          <Fade direction="up" duration={1500}>
            <div className="main-section-text py-5 text-white text-center main-font">
              <h2 dir={dir} className="text-white main-font h1">
                {servicesDataApi?.title.title}
                <br className="d-xlg-block d-none" />
              </h2>
              <p dir={dir} className="lead secondary-text my-4">
                {servicesDataApi?.title.description.split(">")[1].split("<")[0]}
              </p>
            </div>
          </Fade>
          <div className="row g-4">
            {renderServices()}
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <Fade direction="left" duration={1500}>
            <div className="contact-us-footer d-flex align-items-center justify-content-between overflow-hidden z-1 position-relative">
              <img src={fram33} alt="frame" className="position-absolute start-0 top-0 eclipse" />
              <div className="contact-us-footer-text text-white main-font fw-bold">
                <h2>
                  {t("contact-us-footer.h")}
                  <span className="main-text">{t("contact-us-footer.span")}</span>
                </h2>
                <p className="secondary-text my-3">{t("contact-us-footer.p")}</p>
              </div>
              <Link to={"/contact-us"} className="text-decoration-none">
                <button className="my-4 magnet contact-btn-footer px-3 py-2 rounded-3 main-bg">
                  <p className="mb-0 text-white main-font">{t("header.btn")}</p>
                </button>
              </Link>
            </div>
          </Fade>
        </div>
      </section>
    </>
  );
}

export default Services;
